@media (min-width: 1312px){.tlg-hidden-lg {
  display: none !important;
}

:root {
  --container: 124.5rem;
}

.tlg-container-fluid {
  --padding-container: 6rem;
}

.tlg-col-lg-1 {
  --grid-col: 1;
}

.tlg-offset-col-lg-1 {
  --grid-offset-col: 1;
}

.tlg-col-lg-2 {
  --grid-col: 2;
}

.tlg-offset-col-lg-2 {
  --grid-offset-col: 2;
}

.tlg-col-lg-3 {
  --grid-col: 3;
}

.tlg-offset-col-lg-3 {
  --grid-offset-col: 3;
}

.tlg-col-lg-4 {
  --grid-col: 4;
}

.tlg-offset-col-lg-4 {
  --grid-offset-col: 4;
}

.tlg-col-lg-5 {
  --grid-col: 5;
}

.tlg-offset-col-lg-5 {
  --grid-offset-col: 5;
}

.tlg-col-lg-6 {
  --grid-col: 6;
}

.tlg-offset-col-lg-6 {
  --grid-offset-col: 6;
}

.tlg-col-lg-7 {
  --grid-col: 7;
}

.tlg-offset-col-lg-7 {
  --grid-offset-col: 7;
}

.tlg-col-lg-8 {
  --grid-col: 8;
}

.tlg-offset-col-lg-8 {
  --grid-offset-col: 8;
}

.tlg-col-lg-9 {
  --grid-col: 9;
}

.tlg-offset-col-lg-9 {
  --grid-offset-col: 9;
}

.tlg-col-lg-10 {
  --grid-col: 10;
}

.tlg-offset-col-lg-10 {
  --grid-offset-col: 10;
}

.tlg-col-lg-11 {
  --grid-col: 11;
}

.tlg-offset-col-lg-11 {
  --grid-offset-col: 11;
}

.tlg-col-lg-12 {
  --grid-col: 12;
}

.tlg-offset-col-lg-12 {
  --grid-offset-col: 12;
}

.tlg-btn-ico .tlg-btn-label {
  display: inline-block;
  font-size: 1.6rem;
}

.tlg-btn-abo-papier {
  --color-button: #02142B;
  margin-right: 3.2rem;
  border-radius: 18px;
  background-color: #214a6a;
  padding-right: 1.6rem;
}

.tlg-btn-abo-papier .tlg-btn-label {
  color: #fff;
  text-decoration: underline;
}

.tlg-btn-abo-papier .tlg-ico {
  background-color: #fff;
}

.tlg-anchor {
  margin-bottom: 1.6rem;
  font-size: 2.4rem;
  padding-left: 5.2rem;
}

.tlg-anchor:before,
.tlg-anchor:after {
  left: 1.8rem;
}

.tlg-anchor:before {
  width: 8px;
  height: 8px;
}

.tlg-anchor:after {
  left: 1.9rem;
}

.tlg-anchor.tlg-anchor-active {
  font-size: 3.2rem;
}

.tlg-anchor.tlg-anchor-active:before {
  width: 36px;
  height: 36px;
}

.tlg-anchor.tlg-anchor-active:after {
  width: 8px;
  height: 8px;
}

.tlg-game .tlg-delay {
  right: 2.4rem;
}

.tlg-card-title {
  font-size: 2rem;
}

.tlg-now .tlg-card-title {
  font-size: 4rem;
}

.tlg-now .tlg-card-mentions {
  margin-right: 2.4rem;
}

.tlg-ka-header {
  padding: 3rem 6rem;
  height: 11rem;
}

.tlg-section-home {
  --sizeCardSelected: 34rem;
}

.tlg-section-home-content {
  transform: translateY(-30%);
}

.tlg-ka-home {
  --columnLeftSize: 42vw;
}

.tlg-home-message {
  padding-top: 11rem;
}

.tlg-anchors {
  top: calc((100vh - 31rem)/2 + 26rem);
}

.tlg-ka-list {
  padding-top: 15rem;
  margin-bottom: 8rem;
}

.tlg-ka-list section {
  margin-bottom: 2.4rem;
}}